import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ROLES } from '../constants/general.constants';
import { PhoneNumberUtil } from 'google-libphonenumber';

const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);
@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor(
    private translate: TranslateService,
    private permissionSetvice: NgxPermissionsService
  ) {}

  setItemToLocalStorage(name: string, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }
  getItemFromLocalStorage(name: string): any {
    return JSON.parse(localStorage.getItem(name));
  }

  removeItemFromLocalStorage(name: string): any {
    localStorage.removeItem(name);
  }

  checkItemFromLocalStorage(name: string): boolean {
    return !!localStorage.getItem(name);
  }

  fillField(form: UntypedFormGroup, field: string, value: any): void {
    form.controls[field].patchValue(value);
  }

  trackBy(item: any): string {
    return item.id;
  }

  getDate(date: NgbDateStruct | null): Date {
    return new Date(date.year + '/' + date.month + '/' + date.day);
  }

  getStringDate(date: NgbDateStruct | null): string {
    return `${pad(date?.day)}/${pad(date?.month)}/${date?.year}`;
  }

  getISOFormatFromStringDate(value: string): string {
    let date = value?.split(' ')[0];
    const time = value?.split(' ')[1];
    date = date?.split('/').reverse().join('/');

    return new Date(`${date} ${time}`)?.toISOString();
  }

  appendTimeToDate(date: string, time: string): Date {
    return new Date(`${date} ${time}`);
  }

  formatDateForCustomValidators(date: string): string {
    const fieldFormat = new DatePipe('en').transform(date, 'yyyy-MM-dd');
    return fieldFormat;
  }

  getUniqueArray(oldArr: any[], newArr: any[]): any[] {
    if (!oldArr.length) {
      oldArr.push(...newArr);
      return oldArr;
    }
    newArr.forEach((item) => {
      if (!oldArr.find((x) => x.id === item.id)) {
        oldArr.push(item);
      }
    });
    return oldArr;
  }

  getElementTranslatedName(el: any): string {
    const currLang = this.translate.currentLang;
    return currLang === 'ar' ? el.nameAr : el.nameEn ? el.nameEn : el.nameAr;
  }
  getElementTranslatedDescBase(el: any): string {
    const currLang = this.translate.currentLang;
    return currLang === 'ar'
      ? el.descriptionAr
      : el.descriptionEn
      ? el.descriptionEn
      : el.descriptionAr;
  }

  getElementTranslatedDescRest(el: any): string {
    const currLang = this.translate.currentLang;
    return currLang === 'ar'
      ? el.descriptionAr?.substr(50, el.descriptionAr.length)
      : el.descriptionEn?.substr(50, el.descriptionEn.length);
  }

  getElementTranslatedAddress(el: any): string {
    const currLang = this.translate.currentLang;
    let address = '';
    if (currLang === 'ar') {
      if (el.addressAr) {
        address = el.addressAr;
      } else {
        address = el.addressEn;
      }
    } else {
      if (el.addressEn) {
        address = el.addressEn;
      } else {
        address = el.addressAr;
      }
    }
    return address;
    // return currLang === 'ar' ? el.addressAr : el.addressEn;
  }

  getDatesInRange(startDate, endDate): Date[] {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  divideBy100(number: number) {
    return number;
    // return (number).toFixed(2);
  }

  get isUserAdmin() {
    return localStorage.getItem(ROLES)?.includes('superadmin');
  }

  get isUserEventCreator() {
    return localStorage.getItem(ROLES)?.includes('creator');
  }

  get isUserEventUser() {
    return localStorage.getItem(ROLES)?.includes('event-user');
  }

  englishFieldValidationMinDesc(control: FormControl) {
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();
    const isValid = value ? value.length > 10 : true;
    return isValid ? null : { minlength: true };
  }

  englishFieldValidationMaxDesc(control: FormControl) {
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();
    const isValid = value ? value.length < 100 : true;
    return isValid ? null : { maxlength: true };
  }

  removeHTMLTags(html: string) {
    var div = document.createElement('div');
    div.innerHTML = html;
    return div.innerText; // Hello, World
  }

  englishFieldValidationMinName(control: FormControl) {
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();
    const isValid = value ? value.length > 3 : true;
    return isValid ? null : { minlength: true };
  }

  englishFieldValidationMaxName(control: FormControl) {
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();
    const isValid = value ? value.length < 40 : true;
    return isValid ? null : { maxlength: true };
  }

  arabicPatternValidator(control: FormControl) {
    const arabicLettersPattern = '^[\u0621-\u064A\u0660-\u06690-9_ ]+$';
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();

    const isValid = value.match(arabicLettersPattern);
    return isValid ? null : { pattern: true };
  }

  arabicAndEnglishPatternValidator(control: FormControl) {
    const arabicLettersPattern = '^[\u0621-\u064A\u0660-\u0669]+$';
    const englishLettersPattern = '^[a-zA-Z]+$';

    const isValid =
      control.value.match(arabicLettersPattern) ||
      control.value.match(englishLettersPattern);
    return isValid ? null : { pattern: true };
  }

  arabicAndSpecialCharsValidator(
    control: FormControl
  ): ValidationErrors | null {
    const arabicAndSpecialCharsPattern =
      /^[\u0621-\u064A\u0660-\u0669\u066E-\u06D3\u06D5-\u06DC\u06DE-\u06FF\u0750-\u077F\u08A0-\u08BD\uFB50-\uFDFF\uFE70-\uFEFF\s,\/.,،\-()؟\[\]&*^%$#@!"':;]*$/;
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();

    return arabicAndSpecialCharsPattern.test(value) ? null : { pattern: true };
  }

  englishPatternValidator(control: FormControl) {
    const englishLettersPattern = '^[a-zA-Z0-9_ ]+$';
    const value = (control.value || '').replace(/(<([^>]+)>)/gi, '').trim();

    const isValid = value ? value.match(englishLettersPattern) : true;
    return isValid ? null : { pattern: true };
  }

  noOnlyWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace =
        (control.value || '').trim().length === 0 && control.value.length !== 0;
      const isValid = !isWhitespace;
      return isValid ? null : { onlyWhitespace: true };
    };
  }

  atLeastOneRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string[];
      if (value.length === 0) {
        return { atLeastOneRequired: true };
      }
      return null;
    };
  }

  getCategoryColor(category) {
    if (category.nameAr == 'تعليمي') {
      return '#E47D87';
    } else if (category.nameAr == 'فنون و ثقافة') {
      return '#067A91';
    } else if (category.nameAr == 'رياضة') {
      return '#077D55';
    } else if (category.nameAr == 'تراثي') {
      return '#E28300';
    } else if (category.nameAr == 'مجانية') {
      return '#7B61FF';
    } else if (category.nameAr == 'معرض') {
      return '#1E1E1E';
    } else if (category.nameAr == 'كرنفال') {
      return '#8F49DE';
    } else if (category.nameAr == 'عرض') {
      return '#8ED8AC';
    } else if (category.nameAr == 'مهرجان') {
      return '#E4B400';
    } else if (category.nameAr == 'ازياء') {
      return '#D770D9';
    } else if (category.nameAr == 'ألعاب') {
      return '#EA4040';
    } else if (category.nameAr == 'أونلاين') {
      return '#A1A5A3';
    } else if (category.nameAr == 'موسيقى') {
      return '#074479';
    } else {
      return '#074479';
    }
  }

  extractRegionCode(mobileNumber: string): string | null {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parse(mobileNumber);
      const countryCode = number.getCountryCode();
      return `+${countryCode}`;
    } catch (error) {
      return null;
    }
  }


  extractCountryCode(mobileNumber: string): string | null {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parse(mobileNumber);
      const countryCode = phoneUtil.getRegionCodeForNumber(number);
      return countryCode;
    } catch (error) {
      console.error('Error parsing phone number:', error);
      return null;
    }
  }
}
