import { environment } from '../../../environments/environment';

const apiUrl = environment.apiRoot;

// Auth
export const tokenApi = `${apiUrl}/auth/v2/token`;

export const usersApi = `${apiUrl}/users`;
export const adminApi = `${apiUrl}/admin`;
export const commercialRegistrationApi = `${apiUrl}/users/creators/commercial-registration`;
export const userProfileApi = `${usersApi}/profile`;
export const usersCreatorsApi = `${usersApi}/creators`;
export const creatorsApi = `${apiUrl}/creators`;
export const clientsApi = `${apiUrl}/partners`;
export const verifyEmailApi = `${usersApi}/email/activate`;
export const resetPasswordApi = `${usersApi}/password/reset`;
export const otpResendApi = `${usersApi}/otp/resend`;
export const regionsApi = `${apiUrl}/regions`;
export const citiesApi = `${regionsApi}/cities`;
export const audienceApi = `${apiUrl}/common/audience`;
export const eventTypesApi = `${apiUrl}/common/eventType`;
export const contactUsApi = `${apiUrl}/contact-us/create`;
export const eventsApi = `${apiUrl}/events`;
export const eventsFeeApi = `${apiUrl}/eventsFee`;
export const settingsApi = `${creatorsApi}/events`;
export const eventsStatisticsApi = `${apiUrl}/events/statistic/dashboard`;
export const eventsDashboardApi = `${eventsApi}/dashboard`;
export const dashboardApi = `${apiUrl}/dashboard`;
export const publicEventApi = `${apiUrl}/public/event`;
export const publicEventsApi = `${apiUrl}/public/events`;
export const banners = `${apiUrl}/banners`;
export const ticketsApi = `${apiUrl}/events/tickets`;
export const publicPaymentsApi = `${apiUrl}/public/payments`;
export const validateCouponApi = `${apiUrl}/public/payments/promo-code/validate`;
export const attachmentsApi = `${apiUrl}/attachments`;
export const paymentsApi = `${apiUrl}/payments`;
export const accountsApi = `${apiUrl}/users`;
export const statisticsRoleApi = `${accountsApi}/statistics/role`;
export const statisticsStatusApi = `${accountsApi}/statistics/status`;
export const usersManageApi = `${usersApi}/administration`;
export const exportSoldTicketsAdmin = `${adminApi}/tickets/download-excel`;
export const resendEmailActivationLink = `${adminApi}/users`;
export const bookCustomTicketForAdmin = `${adminApi}/payments/invoice`;
export const bookCustomTicketForEventUser = `${apiUrl}/sales-persons/payments/invoice`;
export const getAllTickets = `${adminApi}/payments/tickets/table`;
export const exportAllTickets = `${adminApi}/payments/tickets/table/download`;
export const ticketsStatastics = `${adminApi}/payments/tickets/statistics/status`;
export const resendTickets = `${adminApi}/payments/invoices`;
export const refundInvoice = `${adminApi}/payments/invoice`;
export const addClient = `${adminApi}/partners`;
export const exportSoldTicketsCreator = `${creatorsApi}/tickets/download-excel`;
export const viewInvoiceDetailsCreator = `${creatorsApi}/invoices`;
export const changeEventStatus = `${eventsApi}/dashboard`;
export const relatedEventsUser = `${eventsApi}/users/linked-events`;
export const soldTicketsForSalesPerson = `${paymentsApi}/events`;
export const ticketsTypesForSalesPerson = `${eventsApi}/users/event`;
export const invitationApi = `${eventsApi}/users/invite`;
export const checkInTicketApi = `${apiUrl}/scanners/events/orders`;
export const dailyInventoryReport = `${apiUrl}/scanners/events/daily-inventory`;
export const getPermission = `${apiUrl}/resources`;
export const getUserPermission = `${apiUrl}/permission/user`;
export const updateUserPermission = `${apiUrl}/permission/user`;
export const terminalsApi = `${apiUrl}/terminal`;
export const invitationsApi = `${apiUrl}/invitations`;
export const weaponsApi = `${apiUrl}/weapons`;
export const accessWeaponsApi = `${apiUrl}/access-weapons`;
export const accessWeaponsManagementApi = `${apiUrl}/access-weapons-management`;
export const tagsApi = `${apiUrl}/tags`;
export const buyerMailingTemplate = `${apiUrl}/buyer-mailing-template`;
