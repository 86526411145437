<!--<input type="text"-->
<!--       class="form-control"-->
<!--       [ngClass]="{ 'is-invalid': eventoFormControl.status === 'INVALID' && eventoFormControl.touched }"-->
<!--       placeholder="{{ 'Username' | translate }}"-->
<!--       [formControl]="formControl"-->
<!--       id="username"/>-->
<!--<i class="fas fa-envelope form-icon" parent-class="is-havingIcon"></i>-->
<label class="control-label" *ngIf="label">
  {{ label }}
  <span *ngIf="requiredQuestionFlag" style="color: red; font-size: large">*</span>
</label>

<ng-select [virtualScroll]="true" (scrollToEnd)="reachedEndEvent()" *ngIf="!hasStaticValues" [items]="items"
  bindLabel="name" bindValue="id" [multiple]="multiple" [formControl]="formControl" [placeholder]="placeholder"
  [clearable]="!requiredMessage" [ngClass]="{
    'is-invalid':
      eventoFormControl.status === 'INVALID' && eventoFormControl.touched,
    'is-valid':
      eventoFormControl.status === 'VALID' &&
      eventoFormControl.touched &&
      eventoFormControl.hasValidator()
  }">
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled">{{ "No data found" | translate }}</div>
  </ng-template>
</ng-select>

<ng-select *ngIf="hasStaticValues" [multiple]="multiple" [formControl]="formControl" [placeholder]="placeholder"
  [virtualScroll]="true" (change)="onChange($event)" (scrollToEnd)="reachedEndEvent()">
  <ng-option *ngFor="let item of items" [value]="item.id">{{
    item.name | translate
    }}</ng-option>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled">{{ "No data found" | translate }}</div>
  </ng-template>
</ng-select>

<app-error-input [message]="requiredMessage" *ngIf="
    requiredMessage &&
    eventoFormControl.hasError('required') &&
    eventoFormControl.touched
  ">
</app-error-input>
